import React from "react";
import { Link } from "react-router-dom";
//i18n
import SidebarContent from "./SidebarContent";
import logo from 'assets/images/logo.png'
import logoIcon from 'assets/images/logo-icon.png'

const Sidebar = props => {
	function tToggle() {
		var body = document.body;
		body.classList.toggle("vertical-collpsed");
		body.classList.toggle("sidebar-enable");
	}

	return (
		<div className="vertical-menu">
			<div className="navbar-brand-box">
				<Link to="/dashboard" className="logo logo-dark" tabIndex="-1">
					<span className="logo-sm">
						<img src={logoIcon} alt="logoIcon" width={30} />
					</span>
					<span className="logo-lg">
						<img src={logo} alt="logo" width={130} />
					</span>
				</Link>
			</div>
			<button
				tabIndex="-1"
				onClick={() => {
					tToggle();
				}}
				type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn">
				<i className="uil-bars fz-20" />
			</button>
			<div data-simplebar className="h-100">
				<SidebarContent />
			</div>
		</div>
	);
};

export default Sidebar