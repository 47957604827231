import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DatePicker from "react-datepicker"

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { GET } from 'helpers/api'
import { formatMoney, formatDateBackend, returnSign } from 'helpers/helpers';

function Index() {
	const { t } = useTranslation()


	const [topData, setTopData] = useState([
		{
			'name': 'NUMBER_OF_CONNECTED_POINTS',
			'data': 0,
			'icon': 0,
		},
		{
			'name': 'NUMBER_OF_USERS',
			'data': 0,
			'icon': 0,
		},
		{
			'name': 'AVERAGE_CHECK_AMOUNT',
			'data': 0,
			'icon': 0,
		},
		{
			'name': 'NUMBER_OF_PAYMENTS_WITH_POINTS',
			'data': 0,
			'icon': 0,
		},
		{
			'name': 'AMOUNT_OF_PAYMENTS_BY_POINTS',
			'data': 0,
			'icon': 0,
		},
		{
			'name': 'UNUSED_POINTS',
			'data': 0,
			'icon': 0,
		},
	])
	const [show] = useState(!false)
	const [loader, setLoader] = useState(true)
	const [filterData, setFilterData] = useState({
		'startDate': null,
		'endDate': null,
		'posId': null
	})


	async function getData() {
		var urlParams = ""
		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.startDate)
			urlParams += returnSign(urlParams) + 'startDate=' + filterData.startDate
		if (filterData.endDate)
			urlParams += returnSign(urlParams) + 'endDate=' + filterData.endDate

		const response = await GET('/services/admin/api/main-report' + urlParams)
		if (response) {
			var topDataCopy = [...topData]
			topDataCopy[0]['data'] = response.countPos
			topDataCopy[1]['data'] = response.countUsers
			topDataCopy[2]['data'] = response.avgCheque
			topDataCopy[3]['data'] = response.countPurchasesUsers
			topDataCopy[4]['data'] = response.countAmountUsers
			topDataCopy[5]['data'] = response.usersBalance
			setTopData(topDataCopy)
			setLoader(false)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('STATISTICS')}
				</h4>
				<div className="d-flex flex-wrap justify-content-end">
					<div className="w-25 me-2">
						<div className="calendar-input">
							<DatePicker
								className="form-control"
								dateFormat="dd.MM.yyyy"
								selected={filterData.startDate ? new Date(filterData.startDate) : ''}
								onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
							<i className="uil uil-calendar-alt"></i>
						</div>
					</div>
					<div className="w-25 me-2">
						<div className="calendar-input">
							<DatePicker
								className="form-control"
								dateFormat="dd.MM.yyyy"
								selected={filterData.endDate ? new Date(filterData.endDate) : ''}
								onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
							<i className="uil uil-calendar-alt"></i>
						</div>
					</div>
					<div>
						<button className="btn btn-primary" onClick={() => getData()}>
							{t('FILTER')}
						</button>
					</div>
				</div>
			</div>

			{show &&
				<>
					{!loader &&
						<>
							<div className="row mb-3">
								{topData.map((item, index) => (
									<div className="col-md-4 mb-3" key={index}>
										<div className="card h-100">
											<div className="card-body">
												<div className="d-flex justify-content-between">
													<div className="mt-2">
														<div className="rounded-circle iq-card-icon bg-primary">
															<i className={item.icon}></i>
														</div>
													</div>
													<div className="mt-1">
														<h4 className="d-flex justify-content-end"><b>{formatMoney(item.data)}</b></h4>
														<p className="d-flex justify-content-end text-muted mb-0">{t(item.name)}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								))
								}
							</div>
						</>
					}
					{loader &&
						<>
							<div className="row mb-3">
								<div className="col-md-4">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-4">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-4">
									<Skeleton height={90.2} />
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-md-4">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-4">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-4">
									<Skeleton height={90.2} />
								</div>
							</div>
						</>
					}
				</>
			}
		</>
	)
}

export default Index
