import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

import { GET } from 'helpers/api'
import { formatDate } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)

	async function createUpdate() {
		//
	}

	function openModal(item) {
		var itemCopy = { ...item }
		setSelectedItem(itemCopy)
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
	}

	async function getData() {
		const response = await GET('services/admin/api/notification-pageList', {}, { loader: true })
		if (response) {
			setData(response)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('NOTIFICATIONS')}
				</h4>
				{/* <div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => {
						setSelectedItem({})
						setShowModal(true)
					}}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div> */}
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('TITLE')}</th>
									<th>{t('MESSAGE')}</th>
									<th>{t('DATE')}</th>
									<th>{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}. {item.name}</td>
										<td>{item.titleRu}</td>
										<td>{item.messageRu}</td>
										<td>{formatDate(item.createdDate)}</td>
										<td className="d-flex justify-content-center">
											<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
												<i className="uil-edit-alt"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={() => createUpdate()} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control"
								value={selectedItem.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('LOGIN')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control"
								value={selectedItem.login}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'login': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('PASSWORD')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control"
								value={selectedItem.password}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'password': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
