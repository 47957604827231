import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation'

import { PGET, POST, PUT } from 'helpers/api'
import { regexPhoneNumber, regexNumeric } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()


	const { register, handleSubmit, formState, setValue, getValues, reset } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: { firstName: "", lastName: "", phone: "", userLogin: "", password: "" }
	});
	const { errors } = formState

	const [data, setData] = useState([])
	const [showModal, setShowModal] = useState(false)

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		sendData.phone = '998' + regexNumeric(sendData.phone)

		var response;
		if (sendData.id) {
			response = await PUT('/services/uaa/api/owner', sendData, { loader: true })
		} else {
			response = await POST('/services/uaa/api/owner', sendData, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
			reset()
		}
	}

	function openModal(item) {
		var itemCopy = { ...item }
		if (itemCopy.phone) {
			itemCopy.phone = itemCopy.phone.substring(3, 12)
		}

		for (const [key, value] of Object.entries(itemCopy)) {
			setValue(key, value)
		}
		setShowModal(true)
	}

	function closeModal() {
		reset()
		setShowModal(false)
	}

	async function handleDocumentsInCreate(e, index) {
		var dataCopy = [...data]
		dataCopy[index][e.target.name] = e.target.checked
		setData(dataCopy)
		var sendData = {
			activated: e.target.checked,
			userLogin: dataCopy[index]['userLogin']
		}
		await PUT('/services/uaa/api/owner-activate', sendData, { loader: true })
	}

	async function getData() {
		const response = await PGET('/services/uaa/api/owner-pageList', {}, { loader: true })
		if (response) {
			setData(response.data)
		}
	}



	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('USERS')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => setShowModal(true)}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('FIO')}</th>
									<th>{t('LOGIN')}</th>
									<th>{t('PHONE')}</th>
									<th className="text-center">{t('ACTIVATED')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.firstName + " " + item.lastName}</td>
										<td>{item.userLogin}</td>
										<td>{regexPhoneNumber(item.phone)}</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="form-check form-switch form-switch-lg">
													<input type="checkbox" className="form-check-input"
														name="activated"
														checked={item.activated}
														onChange={(e) => handleDocumentsInCreate(e, index)} />
												</div>
											</div>
										</td>
										<td className="d-flex justify-content-center">
											<div className="table-action-button table-action-primary-button me-2" onClick={() => openModal(item)}>
												<i className="uil-edit-alt"></i>
											</div>
											<Link className="table-action-button table-action-success-button" to={'/pos/create/' + item.userLogin}>
												<i className="uil-plus"></i>
											</Link>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{getValues('id') ?
							<>{t('USER_ADD')}</>
							:
							<>{t('USER_EDIT')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="firstName" {...register('firstName')} />
							<span className="text-danger text-sm">{errors.firstName?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('LAST_NAME')}</label>
							<input type="text" className="form-control" name="lastName" {...register('lastName')} />
							<span className="text-danger text-sm">{errors.lastName?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('PHONE')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone" {...register('phone')} />
							</div>
							<span className="text-danger text-sm">{errors.phone?.message}</span>
						</div>
						{!getValues('id') &&
							<div className="form-group">
								<label>{t('LOGIN')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" name="userLogin" {...register('userLogin')} />
								<span className="text-danger text-sm">{errors.userLogin?.message}</span>
							</div>
						}
						<div className="form-group">
							<label>{t('PASSWORD')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="password" {...register('password')} />
							<span className="text-danger text-sm">{errors.password?.message}</span>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
