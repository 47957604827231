import React, { useEffect, useState } from "react"
import { Link, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Select from 'react-select'
import DatePicker from "react-datepicker"
import { format } from 'date-fns'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation'

import { GET, POST, PUT, FILE } from 'helpers/api'
import { DayOfTheWeek } from "./DayOfTheWeek";

const containerStyle = {
	width: '100%',
	height: '250px'
};

function CreateUpdate() {
	const { t } = useTranslation();
	const { login, id } = useParams();
	const history = useHistory();

	const { register, handleSubmit, formState, control, setValue, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: {
			activated: true,
			address: "",
			phone: "",
			regionId: 10,
			cityId: "",
			gpsPointX: 41.311081,
			gpsPointY: 69.240562,
			imageUrl: "",
			galleryListUrl: [],
		}
	});
	const { errors } = formState

	const [posWorkingDays, setPosWorkingDays] = useState([
		{ days: 1, activated: true, workingStartTime: "00:00", workingEndTime: "23:59", startTime: new Date(new Date().setHours(9, 0, 0, 0)), endTime: new Date(new Date().setHours(18, 0, 0, 0)) },
		{ days: 2, activated: true, workingStartTime: "00:00", workingEndTime: "23:59", startTime: new Date(new Date().setHours(9, 0, 0, 0)), endTime: new Date(new Date().setHours(18, 0, 0, 0)) },
		{ days: 3, activated: true, workingStartTime: "00:00", workingEndTime: "23:59", startTime: new Date(new Date().setHours(9, 0, 0, 0)), endTime: new Date(new Date().setHours(18, 0, 0, 0)) },
		{ days: 4, activated: true, workingStartTime: "00:00", workingEndTime: "23:59", startTime: new Date(new Date().setHours(9, 0, 0, 0)), endTime: new Date(new Date().setHours(18, 0, 0, 0)) },
		{ days: 5, activated: true, workingStartTime: "00:00", workingEndTime: "23:59", startTime: new Date(new Date().setHours(9, 0, 0, 0)), endTime: new Date(new Date().setHours(18, 0, 0, 0)) },
		{ days: 6, activated: true, workingStartTime: "00:00", workingEndTime: "23:59", startTime: new Date(new Date().setHours(9, 0, 0, 0)), endTime: new Date(new Date().setHours(18, 0, 0, 0)) },
		{ days: 7, activated: true, workingStartTime: "00:00", workingEndTime: "23:59", startTime: new Date(new Date().setHours(9, 0, 0, 0)), endTime: new Date(new Date().setHours(18, 0, 0, 0)) },
	])
	const [regions] = useState([{ "id": 1, "name": "Андижанская область" }, { "id": 2, "name": "Бухарская область" }, { "id": 3, "name": "Джизакская область" }, { "id": 4, "name": "Кашкадарьинская область" }, { "id": 5, "name": "Навоийская область" }, { "id": 6, "name": "Наманганская область" }, { "id": 7, "name": "Самаркандская область" }, { "id": 8, "name": "Сурхандарьинская область" }, { "id": 9, "name": "Сырдарьинская область" }, { "id": 10, "name": "город Ташкент" }, { "id": 11, "name": "Ташкентская область" }, { "id": 12, "name": "Ферганская область" }, { "id": 13, "name": "Хорезмская область" }, { "id": 14, "name": "Респ. Каракалпакстан" }])
	const [cities, setCities] = useState([])
	const [data, setData] = useState({
		activated: true,
		address: "",
		phone: "",
		regionId: 10,
		cityId: "",
		gpsPointX: 41.311081,
		gpsPointY: 69.240562,
		imageUrl: "",
		galleryListUrl: [],
	})

	function selectLocation(e) {
		console.log(e.latLng.lat());
		setData({ ...data, gpsPointX: e.latLng.lat(), gpsPointY: e.latLng.lng() })
	}

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("image", e.target.files[0]);

		const response = await FILE('/services/admin/api/upload/image', formData);
		setValue('imageUrl', response.url)
		setData({ ...data, 'imageUrl': response.url })
	}

	async function multiFileUpload(e) {
		let formData = new FormData();
		for (let i = 0; i < e.target.files.length; i++) {
			formData.append('image', this.$refs.files.files[i])
		}

		const response = await FILE('/services/admin/api/upload/images', formData);
		var galleryListUrl = []
		for (let i = 0; i < response.length; i++) {
			galleryListUrl.push(response[i]);
		}
		formData.delete('image')

		setValue('galleryListUrl', galleryListUrl)
		setData({ ...data, 'galleryListUrl': galleryListUrl })
	}

	function deleteFromGalleryListUrl(index) {
		var dataCopy = { ...data }
		dataCopy.galleryListUrl.splice(index, 1);
		setData(dataCopy)
	}

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		sendData.gpsPointX = data.gpsPointX
		sendData.gpsPointY = data.gpsPointY
		sendData.workingDays = posWorkingDays
		sendData.login = login

		var response;
		if (sendData.id) {
			response = await PUT('/services/admin/api/pos', sendData, { loader: true })
			if (response) {
				history.goBack()
			}
		} else {
			response = await POST('/services/admin/api/pos', sendData, { loader: true })
			if (response) {
				history.goBack()
			}
		}
	}

	async function selectRegion(option) {
		setValue('regionId', option.id)
		setValue('cityId', "")
		const response = await GET('/services/admin/api/city-helper/' + option.id)
		if (response.length > 0) {
			setCities(response)
		}
	}

	async function getData() {
		const response = await GET('/services/admin/api/pos/' + id)

		var date = new Date();
		var posWorkingDaysCopy = [...posWorkingDays]
		for (let i = 0; i < response.workingDays.length; i++) {

			const start = response.workingDays[i]['workingStartTime'].split(':');
			const end = response.workingDays[i]['workingEndTime'].split(':');
			var startFormatted = new Date(date.getFullYear(), date.getMonth(), date.getDate(), start[0], start[1], 0, 0);
			var endFormatted = new Date(date.getFullYear(), date.getMonth(), date.getDate(), end[0], end[1], 0, 0);

			posWorkingDaysCopy[i]['activated'] = response.workingDays[i]['activated']
			posWorkingDaysCopy[i]['startTime'] = startFormatted
			posWorkingDaysCopy[i]['endTime'] = endFormatted
			posWorkingDaysCopy[i]['workingStartTime'] = response.workingDays[i]['workingStartTime']
			posWorkingDaysCopy[i]['workingEndTime'] = response.workingDays[i]['workingEndTime']
		}
		setPosWorkingDays(posWorkingDaysCopy)

		const temporaryCities = await GET('/services/admin/api/city-helper/' + response.regionId)
		setCities(temporaryCities)

		for (const [key, value] of Object.entries(response)) {
			setValue(key, value)
		}
		setData({ ...data, 'imageUrl': response.imageUrl, 'gpsPointX': response.gpsPointX, 'gpsPointY': response.gpsPointY })
	}

	function renderWeekName(id) {
		for (let i = 0; i < DayOfTheWeek.length; i++) {
			if (DayOfTheWeek[i]['id'] === id) {
				return DayOfTheWeek[i]['name' + localStorage.getItem('i18nextLng').split('-').join('')]
			}
		}
	}

	function selectTime(time, index, type) {
		var posWorkingDaysCopy = [...posWorkingDays]
		if (type === 'startTime') {
			posWorkingDaysCopy[index]['startTime'] = time
			posWorkingDaysCopy[index]['workingStartTime'] = format(new Date(time), 'HH:mm')
		} else {
			posWorkingDaysCopy[index]['endTime'] = time
			posWorkingDaysCopy[index]['workingEndTime'] = format(new Date(time), 'HH:mm')
		}
		setPosWorkingDays(posWorkingDaysCopy)
	}

	function setDaysActivated(index) {
		var posWorkingDaysCopy = [...posWorkingDays]
		posWorkingDaysCopy[index]['activated'] = !posWorkingDaysCopy[index]['activated']
		setPosWorkingDays(posWorkingDaysCopy)
	}

	async function getCities() {
		const response = await GET('/services/admin/api/city-helper/' + getValues('regionId'))
		if (response.length > 0) {
			setCities(response)
		}
	}

	useEffect(() => {
		if (id) {
			getData()
		} else {
			getCities()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('POS')}
					</h4>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="row mb-5">
							<div className="col-md-3">
								<div className="con-upload">
									<div className="con-img-upload">
										{data.imageUrl &&
											<div className="img-upload">
												<button type="button" className="btn-x-file" onClick={() => setData({ ...data, 'imageUrl': '' })}>
													<i className="uil-times"></i>
												</button>
												<img src={'https://cabinet.redeem.uz' + data.imageUrl}
													style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
											</div>
										}
										{!data.imageUrl &&
											<div className="con-input-upload">
												<input type="file" onChange={(e) => fileUpload(e)} />
												<span className="text-input fz14">
													{t('IMAGE2')}
												</span>
											</div>
										}
									</div>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('SALE_POINT_NAME')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="name" {...register('name')} />
									<span className="text-danger text-sm">{errors.name?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('PHONE')}<span className="required-mark">*</span></label>
									<input type="number" className="form-control" name="phone" {...register('phone')} placeholder="9989XXXXXXXX" />
									<span className="text-danger text-sm">{errors.phone?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('PRICE')}<span className="required-mark">*</span></label>
									<input type="number" className="form-control" name="price" {...register('price')} />
									<span className="text-danger text-sm">{errors.price?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('PRICE')}2<span className="required-mark">*</span></label>
									<input type="number" className="form-control" name="price2" {...register('price2')} />
									<span className="text-danger text-sm">{errors.price2?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('LOSS')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="loss" {...register('loss')} />
									<span className="text-danger text-sm">{errors.loss?.message}</span>
								</div>
							</div>
							<div className="col-md-3">
								<LoadScript googleMapsApiKey="AIzaSyDRnmjaTmZQmjt4sxKaNVW_DqOfp993Afc">
									<GoogleMap
										mapContainerStyle={containerStyle}
										center={{ lat: data.gpsPointX, lng: data.gpsPointY }}
										zoom={11}
										onClick={selectLocation}>
										<Marker position={{ lat: data.gpsPointX, lng: data.gpsPointY }}></Marker>
									</GoogleMap>
								</LoadScript>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>{t('ADDRESS')}</label>
									<input type="text" className="form-control" name="address" {...register('address')} />
									<span className="text-danger text-sm">{errors.address?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('REGION')}<span className="required-mark">*</span></label>
									<Controller
										name="regionId"
										control={control}
										render={({ field }) =>
											<Select
												{...field}
												options={regions}
												value={regions.find(option => option.id === getValues('regionId')) || ''}
												onChange={(option => selectRegion(option))}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>}
									/>
									<span className="text-danger text-sm">{errors?.regionId?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('CITY')}<span className="required-mark">*</span></label>
									<Controller
										name="cityId"
										control={control}
										render={({ field }) =>
											<Select
												{...field}
												options={cities}
												value={cities.find(option => option.id === getValues('cityId')) || ''}
												onChange={(e) => setValue('cityId', e.id)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>}
									/>
								</div>
								<span className="text-danger text-sm">{errors?.cityId?.message}</span>
							</div>
						</div>

						<div className="row mb-5">
							<div className="col-md-6">
								<div className="form-group">
									<h5 className="fw-600">{t('WORKING_DAYS')}</h5>
									<hr />
									<div className="table-responsive">
										<table className="table table-striped mb-0">
											<thead>
												<tr>
													<th></th>
													<th>{t('DAYS_OF_THE_WEEK')}</th>
													<th>{t('OPENING_TIME')}</th>
													<th>{t('CLOSING_TIME')}</th>
												</tr>
											</thead>
											<tbody>
												{posWorkingDays.map((item, index) => (
													<tr key={index}>
														<td>
															<input type="checkbox" className="form-check-input"
																checked={item.activated}
																onChange={() => setDaysActivated(index)} />
														</td>
														<td>
															{renderWeekName(item.days)}
														</td>
														<td>
															{item.activated ?
																<DatePicker
																	className="form-control text-center w-50 h-25"
																	selected={posWorkingDays[index]['startTime'] ? new Date(posWorkingDays[index]['startTime']) : ''}
																	onChange={(date) => selectTime(date, index, 'startTime')}
																	showTimeSelect
																	showTimeSelectOnly
																	timeIntervals={30}
																	timeCaption="Время"
																	timeFormat="HH:mm"
																	dateFormat="HH:mm" />
																:
																<span>-</span>
															}
														</td>
														<td>
															{item.activated ?
																<DatePicker
																	className="form-control text-center w-50 h-25"
																	selected={posWorkingDays[index]['endTime'] ? new Date(posWorkingDays[index]['endTime']) : ''}
																	onChange={(date) => selectTime(date, index, 'endTime')}
																	showTimeSelect
																	showTimeSelectOnly
																	timeIntervals={30}
																	timeCaption="Время"
																	timeFormat="HH:mm"
																	dateFormat="HH:mm" />
																:
																<span>-</span>
															}
														</td>
													</tr>
												))
												}
											</tbody>
										</table>
									</div>


								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<h5 className="fw-600">{t('SETTINGS')}</h5>
									<hr />
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('TARIFF')}</p>
									<div className="col-md-4 p-0">
										<input type="text" className="form-control" name="tariff" {...register('tariff')} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">{t('BALANCE')}</p>
									<div className="col-md-4 p-0">
										<input type="text" className="form-control" name="balance" {...register('balance')} />
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							{data.galleryListUrl.map((item, index) => (
								<div className="col-md-3">
									<div className="con-upload">
										<div className="con-img-upload">
											{data.imageUrl &&
												<div className="img-upload">
													<button type="button" className="btn-x-file" onClick={() => deleteFromGalleryListUrl(index)}>
														<i className="uil-times"></i>
													</button>
													<img src={'https://cabinet.mdokon.uz' + data.galleryListUrl[index]}
														style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
												</div>
											}
											{!data.imageUrl &&
												<div className="con-input-upload">
													<input type="file" onChange={(e) => multiFileUpload(e)} />
													<span className="text-input fz14">
														{t('IMAGE2')}
													</span>
												</div>
											}
										</div>
									</div>
								</div>
							))
							}
							<div className="col-md-3">
								<div className="con-upload">
									<div className="con-img-upload">
										<div className="con-input-upload">
											<input type="text" />
											<span className="text-input fz14">{t('LOGO')}</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="btn-wrapper">
							<Link type="button" className="btn btn-warning btn-rounded btn-wide me-2" to="/pos">{t('CANCEL')}</Link>
							<button type="submit" className="btn btn-primary btn-rounded btn-wide btn-wide">{t('SAVE')}</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate

