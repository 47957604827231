import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Pagination from 'pages/parts/Pagination'

import { PGET, POST } from 'helpers/api'
import { formatDate } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const settings = useSelector(state => state.settings)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function getData() {
		const response = await PGET('/services/admin/api/pos-reviews-pageList', {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function activate(index) {
		var dataCopy = [...data]
		dataCopy[index]["activated"] === true ? (dataCopy[index]["activated"] = false) : (dataCopy[index]["activated"] = true)
		await POST('/services/admin/api/pos-reviews-activate', dataCopy[index], { loader: true })
		getData()
	}

	async function paginate(data) {
		const response = await PGET('/services/admin/api/pos-reviews-pageList', { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('COMMENTS')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('TITLE')}</th>
									<th>{t('MESSAGE')}</th>
									<th>{t('RATING')}</th>
									<th>{t('USER')}</th>
									<th>{t('DATE')}</th>
									<th className="text-center">{t('ACTIVATED')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.title}</td>
										<td>{item.message}</td>
										<td>{item.rating}</td>
										<td>{item.userFullName}</td>
										<td>{formatDate(item.reviewDate)}</td>
										<td>
											<div className="form-check form-switch form-switch-lg d-flex justify-content-center">
												<input type="checkbox" className="form-check-input"
													checked={item.activated}
													onChange={(e) => activate(index)} />
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
					
					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
