export let exportMenu = [
	{
		url: "/dashboard",
		i18n: "HOME",
		icon: "uil-home-alt",
		active: false,
	},
	{
		url: "/owners",
		i18n: "ADMIN",
		icon: "uil-users-alt",
		active: false,
	},
	{
		url: "/cashiers",
		i18n: "CASHIERS",
		icon: "uil-user-square",
		active: false,
	},
	{
		url: "/comments",
		i18n: "COMMENTS",
		icon: "uil-apps",
		active: false,
	},
	{
		url: "/notifications",
		i18n: "NOTIFICATIONS",
		icon: "uil-bell",
		active: false,
	},
	{
		url: "/pos",
		i18n: "POSES",
		icon: "uil-shop",
		active: false,
	},
]